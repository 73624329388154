import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/final-exam-poster.jpg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1981 slasher movie, Final Exam"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 07</h1>
                    <h2>Final Exam</h2>
                    <h3>May 15, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <p><b>Director:</b> Jimmy Huston // <b>Starring:</b> Cecile Bagdadi &amp; Joel S. Rice</p>
                        <div className="player">
                            <iframe src="https://podcasters.spotify.com/pod/show/bryan-jude-white/embed/episodes/07-Final-Exam-e243cdt" height="102px" width="400px" frameborder="0" scrolling="no" title="Bring Me The Axe Horror Podcast episode 7, Final Exam"></iframe>
                        </div>
                        <p><Link to="/transcripts/final-exam">Read the episode transcript</Link></p>
                        <p>This week, Bryan and Dave take a look at the 1981 slasher also-ran, Final Exam, a movie that should be stuffed down the horror movie memory hole were it not for its one secret weapon, a weirdo nerd character named Radish and his ongoing series of bizarre bon mots. Final Exam can't decide if it's Animal House or Halloween and doesn't do a very good job of ripping off either of them. But we're going to ride this one out to see if our precious Radish can survive the killer's knife.</p>
                        <p>Trailer: <OutboundLink href="https://www.youtube.com/watch?v=tA4niBgNOWE" target="_blank" rel="noreferrer">https://www.youtube.com/watch?v=tA4niBgNOWE</OutboundLink></p>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/the-house-on-sorority-row">Next Episode</Link></li>
                        <li><Link to="/episodes/the-howling">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)